<template>
  <v-container>
    <v-bottom-navigation dark color="secondary" v-if="$vuetify.breakpoint.xsOnly" app>
      <v-row class="pa-2">
        <v-col cols="6">
          <v-select
            v-model="doc_type"
            hide-details="auto"
            item-text="label"
            item-value="value"
            :items="docType"
            label="Documento"
            outlined
            dense
            @change="get_oders()"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="status"
            :items="status_lst"
            label="Estado"
            outlined
            hide-details="auto"
            dense
            @change="get_oders()"
          />
        </v-col>
      </v-row>
    </v-bottom-navigation>
    <v-data-table
      calculate-widths
      :headers="header_orders[$vuetify.breakpoint.xsOnly]"
      :items="orders"
      :items-per-page="15"
      item-key="index"
      sort-by="issue_date"
      sort-desc
      mobile-breakpoint="0"
      class="table-cursor elevation-2"
      loading-text="Cargando ..."
      @click:row="view_doc"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> Documentos </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab dark small color="success" @click="newDoc">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="pa-2" v-if="!$vuetify.breakpoint.xsOnly">
          <v-col cols="12" md="3">
            <v-select
              v-model="doc_type"
              hide-details="auto"
              item-text="label"
              item-value="value"
              :items="docType"
              label="Documento"
              outlined
              dense
              @change="get_oders()"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              v-model="issueDateFrom"
              label="Desde"
              hide-details="auto"
              required
              outlined
              type="date"
              dense
              @change="get_oders()"
            />
          </v-col>

          <v-col cols="6" md="3">
            <v-text-field
              v-model="issueDateTo"
              label="Hasta"
              hide-details="auto"
              required
              outlined
              type="date"
              dense
              @change="get_oders()"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              v-model="status"
              :items="status_lst"
              label="Estado"
              outlined
              hide-details="auto"
              dense
              @change="get_oders()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.party_name`]="{ item }" class="text-right">
        {{ item.party_name }}<br />
        <small v-if="$vuetify.breakpoint.xsOnly"
          >{{ item.delivery_date }}@{{ item.delivery_time }}</small
        >
      </template>
      <template v-slot:[`item.delivery_date`]="{ item }" class="text-right">
        {{ item.delivery_date }}<br />
        <small>{{ item.delivery_time }}</small>
      </template>
      <template v-slot:[`item.total`]="{ item }" class="text-right">
        {{ "$ " + parseFloat(item.total).toLocaleString() }}<br />
        <small v-if="$vuetify.breakpoint.xsOnly">{{ item.status }}</small>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="view_doc(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>
    <!-- <v-list three-line>
      <template v-for="(item, index) in orders">
        <v-divider v-if="index > 0" :key="index"></v-divider>
        <v-list-item :key="item.doc_code" @click="view_doc(item)">
          <v-list-item-content>
            <v-list-item-title v-html="item.party_name"></v-list-item-title>
            <v-list-item-subtitle>{{
              item.party_address + " " + item.party_city
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.issue_date }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="text-right">
            <span>
              {{ "$ " + parseFloat(item.total).toLocaleString() }}
            </span>

            <v-list-item-action-text v-text="item.status"></v-list-item-action-text>
            <v-btn icon>
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list> -->
  </v-container>
</template>

<script>
import { webserver, getToday, getIssueDate } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";
import docs_lst from "../../utils/docs.js";

export default {
  components: {},
  mounted() {
    this.company = this.$store.getters.company;
    //var params = this.$route.params;
    //console.log(params);
    this.get_oders();
  },
  data: () => ({
    categories: [],
    doc_type: null,
    docType: docs_lst().docType,
    status_lst: ["PENDIENTE", "DESPACHADO", "ENTREGADO", "CANCELADO"],
    loading_status: false,
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    selectedItem: 0,
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    header_orders: {
      false: [
        {
          text: "Fecha",
          align: "start",
          sortable: true,
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "Orden",
          align: "start",
          value: "doc_code",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },

        {
          text: "Status",
          align: "start",
          value: "status",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
      true: [
        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "total",
        },
      ],
    },
    issueDateFrom: getIssueDate(60),
    issueDateTo: getToday(),
    status: "",
  }),
  methods: {
    newDoc() {
      console.log(this.doc_type);
      this.$router.push({ name: "Doc_e", params: { docType: this.doc_type } });
    },
    get_oders() {
      var qry = {
        table: "docs",
        filters: [
          { field: "account", operator: "=", value: this.$store.state.profile.account },
          { field: "store", operator: "=", value: this.company.code },
          { field: "date(issue_date)", operator: ">=", value: this.issueDateFrom },
          { field: "date(issue_date)", operator: "<=", value: this.issueDateTo },
          {
            field: "status",
            operator: " IN ",
            value: "'PENDIENTE', 'DESPACHADO', 'ENTREGADO', 'CANCELADO'",
          },
        ],
      };
      if (this.status) {
        qry.filters.push({ field: "status", operator: "=", value: this.status });
      }
      if (this.doc_type) {
        qry.filters.push({ field: "doc_type", operator: "=", value: this.doc_type });
      }
      console.log(qry, this.$store.state.company);
      webserver("get_doc_status", qry, (data) => {
        console.log(data);
        this.orders = data;
      });
    },
    view_doc(e) {
      // //console.log(e);
      // if (e.doc_type == 'OR') {
      this.$router.push({
        name: "Doc_e",
        params: { did: e.doc_code },
      });
      // } else  {
      //   this.$router.push({ name: "Doc", params: { did: e.doc_code } });
      // }
    },
  },

  watch: {},
};
</script>

<style></style>
